<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BForm, BFormGroup, BFormInput, BFormTextarea, BTabs, BTab } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import VueSelect from 'vue-select'

import DropZone from '@/components/DropZone'
import { required } from '@/libs/validations'
import ActivityService from '@/services/ActivityService'

export default {
  name: 'CompleteProfileStep',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTabs,
    BTab,
    DropZone,
    VueSlider,
    VueSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: ['data'],
  data() {
    return {
      ActivityService: new ActivityService(),
      ageRangeSteps: [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      categories: [],
    }
  },
  computed: {
    logoPreview() {
      if (typeof this.data.logo === 'string') return this.data.logo
      if (this.data.logo) return URL.createObjectURL(this.data.logo)
      return ''
    },
  },
  methods: {
    async getCategories() {
      const { data } = await this.ActivityService.getCategoriesList()
      this.categories = data
    },
    handleLogoChange(e) {
      const file = e.target.files[0]
      this.data.logo = file
    },
    handleImagesChange(images) {
      this.data.files = images
    },
    getAgeRangeTooltipText(value) {
      if (value < 0.5) return this.$t('New born')
      if (value === 0.5) return `6 ${this.$t('months')}`
      if (value === 19) return this.$t('Adults')
      return `${value} ${value === 1 ? this.$t('year') : this.$t('years')}`
    },
  },
  mounted() {
    this.getCategories()
  },
}
</script>

<template>
  <div class="wrapper">
    <div class="info">
      <h2 class="info-title">{{ $t('Set up your profile!') }}</h2>
      <p class="info-content">{{ $t('complete_profile_help_1') }}</p>
      <div class="screenshot-wrapper">
        <img
          src="@/assets/images/screenshots/provider-details.webp"
          alt="Screenshot of provider details page"
          class="screenshot"
        />
      </div>
      <p class="info-content">{{ $t('complete_profile_help_2') }}</p>
    </div>
    <div class="form-wrapper">
      <div class="form">
        <div class="logo-field">
          <div class="profile-image" @click="$refs.myFile.click()">
            <input ref="myFile" type="file" class="hidden" @change="handleLogoChange" />
            <img
              v-if="logoPreview"
              class="profile-image"
              :src="logoPreview"
              alt="Upload your business logo"
            />
            <div v-else class="profile-image profile-image-empty">+</div>
          </div>
          <p class="logo-field-helper" @click="$refs.myFile.click()">
            {{ logoPreview ? $t('Edit logo') : $t('Please add your business logo') }}
          </p>
        </div>
        <div class="basic-info">
          <p class="section-title">{{ $t('Basic info') }}</p>
          <b-form-group :label="$t('Business name')" label-for="business_name">
            <validation-provider
              #default="{ errors, valid }"
              :name="$t('Business name')"
              rules="required"
            >
              <b-form-input
                id="business_name"
                v-model="data.business_name"
                name="business_name"
                :state="valid ? null : false"
              />
              <small v-for="error in errors" class="text-danger">{{ error }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Business representative')" label-for="representative">
            <validation-provider
              #default="{ errors, valid }"
              :name="$t('Business representative')"
              rules="required"
            >
              <b-form-input
                id="representative"
                v-model="data.name"
                name="representative"
                :state="valid ? null : false"
              />
              <small v-for="error in errors" class="text-danger">{{ error }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="about" :label="$t('Description')" class="description-field">
            <b-form-textarea
              id="about"
              v-model="data.about"
              :placeholder="$t('Description')"
              rows="8"
            />
          </b-form-group>
          <DropZone
            class="images-field"
            :label="$t('Add photos (Min 1 photo Max 8 photos)')"
            :files-thumbnail="data.mediaPreviews"
            :files-i-ds="data.files"
            @data-changed="handleImagesChange"
          />
        </div>
        <div class="contact-info">
          <p class="section-title">{{ $t('Contact info') }}</p>
          <b-form-group :label="$t('Email')" label-for="email" class="position-relative">
            <b-form-input id="email" v-model="data.email" readonly name="email" />
          </b-form-group>
          <b-form-group :label="$t('Phone')" label-for="phone">
            <validation-provider #default="{ errors, valid }" :name="$t('Phone')" rules="required">
              <b-form-input
                id="phone"
                v-model="data.phone"
                name="phone"
                :state="valid ? null : false"
              />
              <small v-for="error in errors" class="text-danger">{{ error }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Website URL')" label-for="website">
            <b-form-input
              id="website"
              v-model="data.website_url"
              placeholder="https://www.toddl.co/example"
            />
          </b-form-group>
          <b-form-group :label="$t('Facebook link')" label-for="facebook">
            <b-form-input
              id="facebook"
              v-model="data.facebook_url"
              placeholder="https://www.facebook.co/example"
            />
          </b-form-group>
          <b-form-group :label="$t('Instagram link')" label-for="instagram">
            <b-form-input
              id="instagram"
              v-model="data.instagram_url"
              placeholder="https://www.instagram.co/example"
            />
          </b-form-group>
        </div>
        <div class="activities-info">
          <p class="section-title">{{ $t('Activities info') }}</p>
          <b-form-group label-for="category" :label="$t('Categories')">
            <vue-select
              id="category"
              v-model="data.categories"
              multiple
              :placeholder="$t('Categories')"
              label="name"
              :options="categories"
              :reduce="option => option.id"
            />
          </b-form-group>
          <b-form-group label-for="age" :label="$t('Age Range')">
            <validation-provider
              #default="{ errors, valid }"
              :name="$t('Age Range')"
              rules="required"
            >
              <vue-slider
                id="age"
                v-model="data.ageRange"
                class="profile-age-range"
                :min="0"
                :max="19"
                :marks="ageRangeSteps"
                :included="true"
                :hide-label="true"
                :process-style="{ backgroundColor: '#6BC4C5' }"
                :tooltip-style="{
                  backgroundColor: '#F8F8F8',
                  borderColor: '#F8F8F8',
                  color: '#0E1A1A',
                  fontWeight: '700',
                }"
                :tooltip="'always'"
                :state="valid ? null : false"
              >
                <template #tooltip="{ value }">
                  <div class="tooltip-slider">{{ getAgeRangeTooltipText(value) }}</div>
                </template>
              </vue-slider>
              <small v-for="error in errors" class="text-danger">{{ error }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.remove-bottom-margin {
  margin-bottom: 0;
}
</style>
<style scoped>
.wrapper {
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

.info {
  max-height: calc(80vh - 4rem - 62px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.info-title {
  margin: 0;
}

.info-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.screenshot-wrapper {
  max-width: 100%;
  align-self: center;
}

.screenshot {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.logo-field {
  display: flex;
  align-items: center;
  gap: 16px;
}
.logo-field-helper {
  margin: 0;
  cursor: pointer;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
  cursor: pointer;
}

.profile-image-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background: #c9c9c9;
  color: #fff;
}

.profile-age-range {
  margin: 48px 24px;
}

.form-group {
  margin: 0;
}

.section-title {
  font-size: 1.15rem;
  line-height: 1.5;
  margin: 0;
  grid-column: 1 / span 2;
}

.contact-info,
.basic-info {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}

.activities-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.images-field,
.description-field {
  grid-column: 1 / span 2;
}

@media screen and (min-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .screenshot-wrapper {
    max-width: 75%;
  }

  .form-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(80vh - 4rem - 62px);
  }

  .form {
    max-width: calc(100% - 8px);
    gap: 32px;
  }

  .profile-age-range {
    margin: 48px 32px;
  }
}
</style>
